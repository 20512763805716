html {
  height: 100vh;
  font-family: Nunito;
}
body {
  min-height: 100vh;
}

#container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.uk-navbar-toggle-icon {
  color: black;
}
#header-nav > li > a {
  color: black !important;
  font-size: 2.2vw !important;
  /* font-size: 1.75rem !important; */
  padding: 0 20px !important;
}
#nav-container {
  align-items: center;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 5vh;
  width: fit-content;
  z-index: 1;
}
@media (max-width: 758px) {
  #nav-container {
    visibility: hidden;
  }
}
#nav-button {
  background: black;
  border: 1px solid black;
  border-radius: 50%;
  color: white;
  width: 36px;
  height: 36px;
  user-select: none;
}
.fa-bars { /* Nav icon */
  left: 11px;
  position: relative;
  top: 6px;
}
#nav {
  list-style-type: none;
}

#contenthead {
  left: 40%;
  top: 45%;
}
#name {
  color: white;
  max-width: 40vw;
}
#career-title {
  color: white;
  margin-left: 3px;
  font-size: 20px;
  max-width: 40vw;
  display: block;
}

#about-me {
  margin-top: 150px;
}
#about-me-intro-and-tstack {
  align-items: center;
  display: grid;
  gap: 5px;
  grid-template-areas:
    "intro-heading    .   tstack-heading    line  .    "
    "intro-heading    .   tstack-frontend   line  icon1"
    "intro-paragraph  .   tstack-backend    line  icon2"
    "intro-paragraph  .   tstack-databases  line  icon3"
    "intro-paragraph  .   tstack-os         line  icon4"
    "intro-paragraph  .   tstack-misc       line  icon5";
  grid-template-columns: 50% 5% auto auto 0.6fr;
  grid-template-rows: 15% 20% 12.5% 20% 12.5% 20%;
  justify-items: center;
}
#introduction-heading {
  align-self: end;
  grid-area: intro-heading;
  margin: 0 7.25vw 30px 7.25vw;
  text-align: center;
}
#introduction-paragraph {
  align-self: start;
  grid-area: intro-paragraph;
  justify-self: end;
  margin: 0 5vw;
  width: 75%;
}
#tech-stack-heading {
  grid-area: tstack-heading;
}
#tech-stack-frontend {
  grid-area: tstack-frontend;
}
#tech-stack-backend {
  grid-area: tstack-backend;
}
#tech-stack-databases {
  grid-area: tstack-databases;
}
#tech-stack-os {
  grid-area: tstack-os;
}
#tech-stack-misc {
  grid-area: tstack-misc;
}
.tech-category {
  background: #f8f8f8;
  display: flex;
  justify-content: center;
  width: 100%;
}
#tech-stack-divider {
  grid-area: line;
  height: 400px;
}
#icon1 { grid-area: icon1; }
#icon2 { grid-area: icon2; }
#icon3 { grid-area: icon3; }
#icon4 { grid-area: icon4; }
#icon5 { grid-area: icon5; }
#favorites {
  display: grid;
  grid-template-areas:
    "movies shows sports food"
    "games travel anime music";
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin: 200px auto auto auto;
  width: 80%;
}
#favorites > .uk-tile {
  align-items: center;
  background-color: transparent;
  border: 1px solid #ebe9e8;
  /* border-right: 1px solid #ebe9e8; */
  color: #1d1e21;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#favorites > .uk-tile:hover {
  background: #1d1e21;
  color: #fff;
  text-shadow: none;
}
/* .uk-slider-items {
  min-height: 0 !important;
} */
/* .uk-tile.top-half { border-top: 1px solid #ebe9e8; } */
/* .uk-tile.left-most { border-left: 1px solid #ebe9e8; } */


#projects {
  align-items: center;
  display: grid;
  grid-template-areas:
    "project-2  project-1"
    "project-4  project-3";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  justify-items: center;
  margin-top: 100px;
}
.project {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#project-1 {
  grid-area: project-1;
}
#project-2 {
  grid-area: project-2;
  position: relative;
  top: 300px;
}
#project-3 {
  grid-area: project-3;
  position: relative;
  top: 100px;
}
#project-4 {
  grid-area: project-4;
  position: relative;
  top: 500px;
}
.project > h3 {
  text-align: center;
  max-width: 75%;
}
.project > p { width: 80%; }
.project-tech {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 25px 0;
  width: 75%;
}
.project-tech > .uk-badge {
  /* background-color: #beffff; */
  /* color: black; */
  margin: 5px;
}
/* .uk-badge { margin: 5px; } */
.read-more-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.read-more-grid > button {
  font-size: 0.7rem;
  padding: 0 5px;
}
.read-more-grid > hr {
  border-top: 2px solid black;
  margin: 25% 0;
}

#contact {
  align-items: center;
  display: grid;
  grid-template-areas:
    "contact-hook"
    "contact-header"
    "contact-links";
  grid-template-rows: repeat(3, auto);
  justify-items: center;
  margin-top: 600px;
}
#contact-hook {
  grid-area: contact-hook;
}
#contact-header {
  grid-area: contact-header;
}
#contact-links {
  display: grid;
  grid-area: contact-links;
  grid-template-areas:
    "social line fun";
  grid-template-columns: 1fr auto 1fr;
}
#links-social > a:hover, #links-fun > a:hover {
  text-decoration: none;
}
/* Can handle two cases. (1) with link (2) without link */
#links-social > a > div, #links-social > div, #links-fun > a > div, #links-fun > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e5e5e5;
  width: 100%;
  line-height: 38px;
}
/* Can handle two cases. (1) with link (2) without link */
#links-social > a > div > div, #links-social > div > div, #links-fun > a > div > div, #links-fun > div > div {
  text-align: center;
  width: 100%;
}
#link-linkedin > i, #link-linkedin > div { color: #2867B2; }
#link-github > i, #link-github > div { color: #333; }
#link-email > i, #link-email > div { color: #F7A6B9; }
#link-discord > i, #link-discord > div { color: #7289DA; }
#link-spotify > i, #link-spotify > div { color: #1DB954; }
#link-blizzard > div { color: #91c0c7; } /* ace5ee */
#link-riot > div { color: rgb(191, 55, 59); }

#resume {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin-top: 100px;
}
#resume > div {
  display: flex;
  flex-direction: row;
}
#resume > div > a {
  border: 0.5px solid !important;
  margin: 0px 5px !important;
}

footer {
  margin-top: 100px;
}
footer > div {
  margin: auto;
  text-align: center;
}



@media only screen and (max-width: 768px) {
  #name {
    font-size: 30px;
  }
  #career-title {
    font-size: 12px;
  }
  #id-nav {
    display: none;
  }
  #about-me {
    margin-top: 25px;
  }
  #about-me-intro-and-tstack {
    grid-template-areas:
      "intro-heading  intro-heading intro-heading intro-heading"
      "intro-paragraph  intro-paragraph intro-paragraph intro-paragraph"
      "tstack-heading  tstack-heading     tstack-heading  tstack-heading"
      ".  tstack-frontend    icon1 ."
      ".  tstack-backend     icon2 ."
      ".  tstack-databases   icon3 ."
      ".  tstack-os          icon4 ."
      ".  tstack-misc        icon5 ." ;
    grid-template-columns: 10% auto 1fr auto;
    grid-template-rows: repeat(8, auto);
  }
  #introduction-heading {
    justify-self: center;
  }
  #introduction-paragraph {
    justify-self: center;
  }
  .tstack-icon {
    width: 32px;
    height: 32px;
  }
  #tech-stack-divider {
    display: none;
  }
  #icon1 { font-size: 36px; }
  #icon2 { font-size: 36px; }
  #icon3 { font-size: 36px; }
  #icon4 { font-size: 36px; }
  #icon5 { font-size: 36px; }
  #favorites {
    margin-top: 100px;
  }
  .fav-icon {
    font-size: 36px !important;
  }
  #anime-img {
    width: 36px;
  }
  .uk-slider-items {
    min-height: 0 !important;
  }
  #projects {
    display: flex;
    flex-direction: column;
  }
  #project-1 {
  }
  #project-2 {
    top: 0px;
  }
  #project-3 {
    top: 0px;
  }
  #project-4 {
    top: 0px;
  }
  #contact-hook {
    /* font-size: 19px; */
    font-size: 1em;
  }
  #contact-links {
    grid-template-areas:
      "social"
      "line"
      "fun";
    grid-template-columns: auto;
    grid-template-areas: repeat(3, auto);
  }
  #links-line { 
    border-top: 1px solid #ccc;
    height: 1px;
    margin: 10px auto;
    width: 80%;
  }
}

@media only screen and (max-width: 550px) { /* 550 is based off personal observation when responsive design of sliders do not act as expected*/
  .slider-grid {
    grid-area: 2 / 1 / span 1 / span 4 !important; /* Once the style attribute is not inline in index.html, can remove !important*/
  }
  .uk-slider-items > li {
    width: 100%;
  }
}

@media only screen and (max-width: 320px) { /* 320 is based off chrom dev tools breakpoints */
  #contenthead {
    top: 55%;
  }
  #name {
    font-size: 20px;
  }
  #career-title {
    font-size: 10px;
  }
}